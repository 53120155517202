/*  eslint-disable */
import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import { navigate } from "gatsby"
import check from "./check.svg"
import RadioInput from "./RadioInput"

import * as icons from "./icons"
import { plans } from "."

const PlanPanelsItemFeatures = styled(({ className, style, features }) => (
  <section className={className} style={style}>
    {features.map((feature, index) => (
      <div className="item" key={index}>
        <img
          src={check}
          alt="checkmark"
          style={{ marginRight: 10, marginTop: 5 }}
        />

        <div style={{ flex: 1 }}>{feature}</div>
      </div>
    ))}
  </section>
))`
  margin-bottom: 20px;
  .item {
    line-height: 1.4;
    text-align: left;
    padding-top: 10px;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
  }
  display: none;
  order: 4;

  @media (min-width: 700px) {
    display: block;
    order: unset;
    padding-top: 20px;
  }
`

PlanPanelsItemFeatures.propTypes = {
  features: PropTypes.arrayOf(PropTypes.string).isRequired,
}

const Button = styled.button`
  padding: 12px 20px;
  background-color: rgba(18, 131, 255, 0.17);
  color: #1283ff;
  border-radius: 20px;
  font-weight: bold;
  outline: none;
  cursor: pointer;

  :focus {
    outline: none;
  }

  @media (max-width: 700px) {
    display: none;
  }
`

const Icon = ({ src }) => (
  <section style={{ flexShrink: 0, width: 50, marginBottom: 15 }}>
    <img
      style={{ objectFit: "contain", height: "100%" }}
      alt="icon"
      src={src}
    />
  </section>
)

Icon.propTypes = {
  src: PropTypes.string.isRequired,
}

const RecommendedOverlay = styled(({ className }) => (
  <div className={className}>
    <div className="label">Recommended for you</div>
  </div>
))`
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;

  border-radius: 12px;
  border: 2px solid #1283ff;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: -1;

  .label {
    display: inline-block;
    margin-top: -20px;
    background: #1283ff;
    color: white;
    font-weight: bold;
    border-radius: 15px;
    padding: 10px;
    font-size: 13px;
  }
`

const Name = styled.div`
  text-transform: uppercase;
  letter-spacing: 2px;
  color: #0f1722;
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 20px;
  @media (max-width: 700px) {
    margin-bottom: 10px;
  }
`

const EmployeeRange = styled(({ className, children }) => (
  <section className={className}>
    <strong>{children}</strong>
    {" employees"}
  </section>
))`
  margin-bottom: 20px;
  @media (max-width: 700px) {
    order: 3;
    margin-bottom: 10px;
  }
`

const Cost = styled(({ className, cost, billingCycle, plan }) => (
  <section className={className}>
    <span className="cost">{cost}</span>
    <span>{`/month`}</span>
    {plan &&
      (billingCycle === "year" && (
        <div
          style={{ color: "#0f1722" }}
          className="mt-2 text-sm leading-normal"
        >
          <div style={{ fontSize: "16px" }} className="font-italic ">
            Billed {plan.costYearTotal} Yearly
          </div>
          <div>
            <span className="font-bold" style={{ color: "#1283ff" }}>
              Save {plan.costYearSavings}
            </span>{" "}
            on yearly subscription
          </div>
        </div>
      ))}
  </section>
))`
  margin-bottom: 20px;

  margin-top: auto;
  color: #a7aaae;

  .cost {
    color: #60a042;
    font-size: 30px;
  }
  text-align: center;
  @media (max-width: 700px) {
    margin-bottom: 10px;
    text-align: left;
  }
`

const CostContact = styled(({ className }) => (
  <section className={className}>
    <div className="label">
      <div
        style={{ fontSize: "20px", fontWeight: "600", marginBottom: "0.5rem" }}
      >
        Have more than 200 employees?
      </div>
      <div style={{ color: "black", lineHeight: "1.3" }}>
        Give us a call at <div>+1(646)-918-8673,</div> or click “Select” book a
        demo
      </div>
    </div>
  </section>
))`
  margin-bottom: 10px;
  color: #a7aaae;
  .label {
    text-align: center;
    font-size: 14px;
    padding: 10px;
    color: #60a042;
    @media (max-width: 700px) {
      text-align: left;
      padding-left: 0;
    }
  }
  @media (min-width: 700px) {
    margin-top: auto;
    margin-bottom: 20px;
  }
`

const PlanDetails = styled.div`
  display: flex;

  flex: 1;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  @media (max-width: 700px) {
    margin-left: 20px;
    align-items: flex-start;
    margin-right: auto;
  }
`

const AndDivider = styled.div`
  text-align: center;
  color: #a7aaae;
  display: none;
  @media (min-width: 700px) {
    display: block;
  }
`

const PlanPanelsItem = styled(
  ({
    className,
    icon,
    name,
    employeeRange,
    features,
    billingCycle,
    costMonth,
    costYear,
    recommended,
    onClick,
    active,
    plan,
  }) => (
    <div
      className={className}
      onClick={() =>
        typeof window !== "undefined" && window.innerWidth < 700 && onClick()
      }
    >
      {recommended && <RecommendedOverlay />}
      <Icon src={icon} />
      <PlanDetails>
        <Name>{name}</Name>
        <EmployeeRange>{employeeRange}</EmployeeRange>
        <AndDivider>— and —</AndDivider>
        <PlanPanelsItemFeatures
          style={
            active
              ? {
                  display: "block",
                }
              : {}
          }
          features={features}
        />

        {costMonth || costYear ? (
          <Cost
            plan={plan}
            cost={plan && (billingCycle === "month" ? costMonth : costYear)}
            billingCycle={billingCycle}
          />
        ) : (
          <CostContact />
        )
        /* if there is no enterprise,  */
        // typeof window !== "undefined" && window.innerWidth < 700 ? (
        //   <Cost cost={"???"} billingCycle={billingCycle} />
        // ) : (
        // )
        }

        <Button
          type="submit"
          onClick={() => {
            onClick()
          }}
        >
          Select
        </Button>
      </PlanDetails>

      <RadioInput value={active} onChange={onClick} />
    </div>
  )
)`
  flex: 1;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  background-color: #f3f4f6;
  border-radius: 12px;
  padding: 40px 20px;
  min-width: 200px;

  color: #0f1722;
  font-size: 16px;
  z-index: 1;

  @media (max-width: 700px) {
    flex-direction: row-reverse;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 20px;
  }
`

PlanPanelsItem.propTypes = {
  icon: PropTypes.string,
  name: PropTypes.string.isRequired,
  billingCycle: PropTypes.oneOf(["year", "month"]).isRequired,
  employeeRange: PropTypes.string.isRequired,
  features: PropTypes.arrayOf(PropTypes.string).isRequired,
  costMonth: PropTypes.string.isRequired,
  costYear: PropTypes.string.isRequired,
  recommended: PropTypes.bool,
}

PlanPanelsItem.defaultProps = {
  recommended: false,
  icon: icons.seedling,
}

export default PlanPanelsItem
