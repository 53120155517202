/*  eslint-disable */
import React from "react"
import styled from "styled-components"
import Stepper from "react-stepper-horizontal"

const StepperWrapper= styled.div`
  width:200px;
  margin: -12px auto 18px auto;
`

const StyledStepper = ({activeStep})=>(
    <StepperWrapper>
        <Stepper activeStep={activeStep} size={12} defaultColor="white" circleFontSize={0} activeColor="#60a042" completeColor="#60a042" completeBarColor="#60a042" defaultBorderColor="grey" barWitdh={2} defaultBorderWidth={1} steps={ [1,2] } />
    </StepperWrapper>
)

export default StyledStepper