/*  eslint-disable */

import { gql, useMutation } from "src/apolloClient"

const useCharge = () => {
  const [subscribe] = useMutation(gql`
    mutation subscribe($input: ChargeInput) {
      charge(input: $input)
        @rest(path: "/charges", bodyKey: "input", method: "POST")
    }
  `)

  return { subscribe }
}

export default useCharge
