/*  eslint-disable */
import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import { StripeProvider, Elements } from "react-stripe-elements"

import Payment from "../components/Payment"
import config from "../config"

const PaymentPage = ({ ...props }) => {
  const [stripe, setStripe] = useState(null)
  useEffect(() => {
    setStripe(window.Stripe(config.STRIPE_PUBLISHABLE_KEY))
  }, [])
  return (
    <React.Fragment>
      <StripeProvider stripe={stripe}>
        <Elements>
          <Payment {...props} />
        </Elements>
      </StripeProvider>
    </React.Fragment>
  )
}

PaymentPage.propTypes = {
  location: PropTypes.any.isRequired,
}

export default PaymentPage
