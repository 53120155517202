/*  eslint-disable */
import React, { useState } from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"
import styled from "styled-components"
import FlexNav from "../FlexNav"
import WidthWrapper from "src/components/widthWrapper"
import { plans } from "src/components/Plans/PlanPanels"
import { injectStripe } from "react-stripe-elements"
import StyledStepper from "../StyledStepper"
import { BackButton } from "../StyledButton"
import PaymentLoadingIcon from "./paymentLoading"
import config from "../../config"
import constant from "src/utils/constant"

import {
  Title,
  TrialNotice,
  PlanDetail,
  Container,
  OuterContainer,
  BackButtonContainer,
  Input,
  PaymentForm,
  Button,
  Layout,
  Spinner,
  SubSpinner,
} from "./components"
import Form, { useFormState } from "../Form"
import useCurrentUser from "src/hooks/useCurrentUser"

import Header from "../Header"
import useCharge from "../../hooks/useCharge"

const Checkbox = props => (
  <label
    style={{ marginBottom: "20px", fontSize: "16px", color: "#a5abb4" }}
    className="block text-gray-500 font-bold"
  >
    <div className="flex">
      <input className="mr-2 appearance-none leading-tight" type="checkbox" />
      <div className="ml-2">
        I agree to{" "}
        <Link
          style={{ color: "#007FFF" }}
          className="no-underline"
          to="/terms-and-conditions"
        >
          terms & conditions
        </Link>{" "}
        and acknowledge the{" "}
        <Link
          style={{ color: "#007FFF" }}
          className="no-underline"
          to="/privacy"
        >
          privacy policy
        </Link>
      </div>
    </div>
  </label>
)

let Payment = ({ transition, stripe, context = {} }) => {
  const { user } = context
  const [isFormSubmitted, setIsFormSubmitted] = useState(false)
  const { subscribe } = useCharge()
  const plansFormState = useFormState("plans")
  const plan =
    plansFormState &&
    plansFormState.values &&
    plansFormState.values.selectedPlan

  const billingCycle =
    plansFormState &&
    plansFormState.values &&
    plansFormState.values.billingCycle
      ? "month"
      : "year"

  const planData = plan && plans.find(planItem => planItem.name === plan)

  const planId =
    planData &&
    (billingCycle === "year" ? planData.yearPlan : planData.monthPlan)

  const initialValues = user && {
    name: `${user.first_name} ${user.last_name}`,
    email: user.email,
  }

  const buttonStyle = {
    background: "#E0E0E0",
    width: 200,
    padding: 16,
    textAlign: "center",
    margin: "0 auto",
    marginTop: 32,
  }

  return initialValues ? (
    <Layout>
      <WidthWrapper>
        <Header />
        <OuterContainer>
          <BackButtonContainer>
            <BackButton onClick={() => transition("BACK")} />
          </BackButtonContainer>

          <Container>
            <Title />
            <StyledStepper activeStep={0} />

            <TrialNotice>
              <div style={{ marginBottom: "0.5rem" }}>
                <strong>
                  Your card won't be charged until after your {constant.trialDays}-day free trial
                  ends.
                </strong>
              </div>
              <div>
                Need a hand? Give our award-winning support team a shout: +1
                646-918-8673, or
                <a
                  style={{
                    textDecoration: "none",
                    color: "#60a042",
                  }}
                  href="mailto:hello@airmason.com "
                >
                  <span> email us at hello@airmason.com</span>
                </a>
              </div>
            </TrialNotice>
            {plan && (
              <PlanDetail
                plan={plan}
                planData={planData}
                billingCycle={billingCycle}
                onClick={() => transition("BACK")}
              />
            )}
            <Form
              name="payment"
              initialValues={initialValues}
              onSubmit={async values => {
                setIsFormSubmitted(true)

                const { token } = await stripe.createToken({
                  type: "card",
                  name: values.name,
                  email: values.email,
                })

                if (!token) {
                  setIsFormSubmitted(false)
                  throw Error("Payment is Invalid")
                }

                try {
                  await subscribe({
                    variables: {
                      input: {
                        token: token.id,
                        plan: planId,
                        coupon: values.coupon,
                      },
                    },
                  })
                } catch (e) {
                  setIsFormSubmitted(false)
                  throw Error(e.errors)
                }

                window.location.replace(
                  `${config.HANDBOOK_HOST}/handbooks/create`
                )
              }}
            >
              <Form.Field
                name="name"
                component={Input}
                placeholder="Your name"
              />
              <Form.Field
                name="email"
                component={Input}
                placeholder="Your email"
              />

              <PaymentForm />
              <Form.Field name="tos" component={Checkbox} />
              <Form.Field
                name="coupon"
                component={Input}
                placeholder="Enter any promo code"
              />

              <Button disabled={isFormSubmitted}>
                {!isFormSubmitted ? (
                  `Start building your handbook!`
                ) : (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      margin: "0 1rem",
                    }}
                  >
                    Payment processing ...
                    <PaymentLoadingIcon />
                  </div>
                )}
              </Button>
            </Form>
          </Container>
          <BackButtonContainer style={{ background: "transparent" }} />
        </OuterContainer>
      </WidthWrapper>
    </Layout>
  ) : null
}

Payment.propTypes = {
  location: PropTypes.any.isRequired,
}

Payment = injectStripe(Payment)

export default Payment
