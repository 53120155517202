/*  eslint-disable */
import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"

const Select = ({ onChange, value, children, ...props }) => (
  <div {...props}>
    {React.Children.map(children, child =>
      React.cloneElement(child, {
        handleSelect: onChange || (() => {}),
        active: child.props.value === value,
        currentValue: value,
      })
    )}
  </div>
)

Select.propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.any.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
}

const Button = styled.button`
  background: none;
  flex: 1;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: none;

  :focus {
    outline: none;
  }
`

Select.Option = ({
  value,
  children,
  active,
  handleSelect,
  className,
  currentValue,
  ...props
}) =>
  React.cloneElement(children, {
    onClick: () => {
      handleSelect(value)
    },
    className: className.concat(active ? " active" : ""),
    active,
    value: currentValue,
  })

Select.Option.propTypes = {
  value: PropTypes.any.isRequired,
  className: PropTypes.string,
  active: PropTypes.bool.isRequired,
  children: PropTypes.node.isRequired,
  handleSelect: PropTypes.func.isRequired,
}

Select.Option.defaultProps = {
  className: "",
}

export default Select
