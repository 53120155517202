import React from "react"
import "./paymentLoading.css"
import styled from "styled-components"

const IconWrapper = styled.div`
  margin-right: 1rem;
`

export default () => (
  <IconWrapper>
    <div className="lds-ring">
      <div />
      <div />
      <div />
      <div />
    </div>
  </IconWrapper>
)
