import styled from "styled-components"

const FlexNav = styled.div`
  max-width: 951px;
  margin: 0 auto;
  display: flex;
`

FlexNav.Left = styled.div`
  flex: 1;
  display: flex;
  justify-content: flex-start;
  align-items: center;
`

FlexNav.Center = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
`
FlexNav.Right = styled.div`
  flex: 1;
  display: flex;
  justify-content: flex-end;
  align-items: center;
`
export default FlexNav
