import PropTypes from "prop-types"
/*  eslint-disable */
import React from "react"

import { useFormState } from "../../Form"
import Select from "../../select"
import * as icons from "./icons"
import styles from "./index.module.css"
import PlanPanelsItem from "./PlanPanelsItem"

// import { StaticQuery, graphql } from 'gatsby';

// const StirpePlansMonthly = () => {}

// <StaticQuery
//   query={graphql`
//     {
//       allStripePlan(
//         sort: { fields: amount }
//         filter: { interval: { eq: "month" }, active: { eq: true } }
//       ) {
//         nodes {
//           nickname
//           amount
//           id
//           interval
//           currency
//         }
//       }
//     }
//   `}
//   render={data => }
// />;

export const plans = [
  {
    icon: icons.seedling,
    name: "Seedling",
    employeeRange: "10 or less",
    features: [
      "1 Digital Handbook",
      "Digital Signature Capabilities",
      "Mobile-responsive Templates",
      "Real-time Collaboration Capabilities",
    ],
    costMonth: "$25",
    costYear: "$20",
    costYearTotal: "$240",
    costYearSavings: "20%",
    employeeRange: "0 - 10",
    yearPlan: "plan_FvlUSyFyOZUPc5",
    monthPlan: "plan_FPuiEQx3bgDrQR",
  },
  {
    icon: icons.startup,
    name: "Startup",
    employeeRange: "11 to 20",
    features: [
      "Up to 5 Handbooks",
      "Digital Signature Capabilities",
      "Mobile-responsive Templates",
      "Real-time Collaboration Capabilities",
    ],
    costMonth: "$49",
    costYear: "$37",
    costYearTotal: "$444",
    costYearSavings: "23%",
    employeeRange: "11 - 20",
    yearPlan: "plan_FSxkTGXGkaC7jn",
    monthPlan: "plan_FPunwOOopvdUe0",
  },
  {
    name: "Small Biz",
    icon: icons.smallBiz,
    employeeRange: "21 to 200",
    features: [
      "Unlimited Handbooks",
      "All Startup features",
      "Payroll Software Integration",
      "Version Tracking",
    ],
    costMonth: "$149",
    costYear: "$83",
    costYearTotal: "$999",
    costYearSavings: "44%",
    employeeRange: "21 - 200",
    yearPlan: "plan_FvlSHCQ4S2abzw",
    monthPlan: "plan_FPulljGHqdifq4",
  },
  {
    icon: icons.enterprise,
    name: "Enterprise",
    employeeRange: "Over 200",
    features: [
      "Creative Support",
      "Custom Integrations",
      "Dedicated Account Manager",
      "Monthly signature reports",
    ],
    employeeRange: "201+",
  },
]

const PlanPanels = ({ value, onChange, recommendedPlan }) => {
  const plansFormState = useFormState("plans")

  const billingCycle =
    plansFormState &&
    plansFormState.values &&
    plansFormState.values.billingCycle
      ? "month"
      : "year"

  return (
    <Select className={styles.PlanPanels} onChange={onChange} value={value}>
      {plans.map((plan, index) => (
        <Select.Option key={index} value={plan.name}>
          <PlanPanelsItem
            billingCycle={billingCycle}
            icon={plan.icon}
            name={plan.name}
            employeeRange={plan.employeeRange}
            features={plan.features}
            costMonth={plan.costMonth}
            costYear={plan.costYear}
            recommended={recommendedPlan === plan.name}
            plan={plan}
            key={index}
          />
        </Select.Option>
      ))}
    </Select>
  )
}
PlanPanels.propTypes = {
  value: PropTypes.any.isRequired,
  onChange: PropTypes.func.isRequired,
}

export default PlanPanels
