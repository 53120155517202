/*  eslint-disable */
import React from "react"
import styled from "styled-components"
import PropTypes from "prop-types"
import { CardElement } from "react-stripe-elements"
import constant from "src/utils/constant"

export const Title = styled(({ className }) => (
  <div className={className}>Enter payment details</div>
))`
  color: #0f1722;
  margin: 20px auto;
  font-size: 30px;
  text-align: center;
  color: #0f1722;
`

export const PlanDetail = styled(
  ({ className, billingCycle, onClick, plan, planData }) => (
    <div className={className} onClick={onClick}>
      <div>
        <strong>{plan} plan</strong> —{" "}
        {billingCycle === "month" ? "Monthly" : "Yearly"} billing
      </div>
      <div>
        <span className="cost">
          {billingCycle === "month"
            ? planData.costMonth
            : planData.costYearTotal}
        </span>
        /{billingCycle} after {constant.trialDays} day free trial
      </div>
    </div>
  )
)`
  color: #0f1722;
  padding: 25px 20px;
  border-radius: 8px;
  color: #0f1722;
  background: #fff6e6;
  border: 2px solid #efe3cf;
  line-height: 1.6;
  margin-bottom: 20px;
  cursor: pointer;
  .cost {
    color: #60a042;
  }
`

export const TrialNotice = styled(({ className, children }) => (
  <div className={className}>{children}</div>
))`
  color: #60a042;
  text-align: center;
  // background-image: url(${require("./smudge.svg")});
  line-height: 1.4;
  background-size: cover;
  width: fit-content;
  margin: 20px auto;
`

const Error = styled.div`
  color: tomato;
  margin-top: 10px;
`

export const Input = styled(props => (
  <React.Fragment>
    <input {...props} />
    <Error>{props.error}</Error>
  </React.Fragment>
))`
  width: 100%;
  box-sizing: border-box;
  padding: 15px;
  color: #0f1722;
  border-radius: 4px;
  border: 1px solid #d1d1d1;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.07);
  outline: none;
  background: white;
  margin-bottom: 20px;
  ::placeholder {
    color: #a5abb4;
    opacity: 1;
  }

  @media (max-width: 700px) {
    max-width: 100%;
  }
`

Input.propTypes = {
  error: PropTypes.string,
}

Input.defaultProps = {
  error: undefined,
}

const CardElementInput = props => <Input as={CardElement} {...props} />

export const PaymentForm = () => <CardElementInput />

export const Button = styled.button`
  width: 100%;
  border-radius: 25px;
  margin-left: auto;
  padding: 15px 20px;
  background: #1283ff;
  color: white;
  outline: none;
  font-size: 18px;
  font-weight: bold;

  :focus {
    outline: none;
  }

  :disabled {
    opacity: 0.6;
  }
`

export const Layout = styled.div`
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-position: bottom left;
  background-size: contain;
  background-image: url(${require("./background.svg")});
`

export const OuterContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-direction: column-reverse;
  width: 100%;
  @media screen and (min-width: 950px) {
    flex-direction: row;
  }
`

export const Container = styled.div`
  margin: 0 auto;
  width: 320px;
  align-self: center;
`

export const BackButtonContainer = styled.div`
  width: 320px;
  margin: 0 auto; // width: 100%

  transition: all 2s;
  flex-grow: 1;
  @media screen and (min-width: 950px) {
    display: block;
    width: auto;
    width: 320px;
    flex-grow: 1;
  }
`
