/*  eslint-disable */
import React from "react"
import PropTypes from "prop-types"
import styles from "./index.module.css"

const RadioInput = props => {
  return (
    <label className={styles.RadioInput}>
      <input
        defaultValue
        checked={props.value}
        onChange={e => props.onChange(e.target.checked)}
        className={styles.input}
        type="checkbox"
      />
      <div className={styles.toggle}>
        <span className={styles.slider} />
      </div>
    </label>
  )
}

RadioInput.propTypes = {
  value: PropTypes.any.isRequired,
  onChange: PropTypes.func.isRequired,
}

export default RadioInput
